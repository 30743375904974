import { React, useEffect, useState } from 'react';
import { Button, Segment, Header } from 'semantic-ui-react';
import jwt_decode from 'jwt-decode';

export const Login = ({ user, setUser, setCredential, setReprintBy }) => {

  const clientId = process.env.REACT_APP_GoogleCloud_ClientID

  const handleCallbackResponse = async (response) => {
    var userObject = jwt_decode(response.credential);
    console.log(userObject)
    setUser(userObject);
    setReprintBy(userObject.name)
    document.getElementById('signInDiv').hidden = true;

    console.log(userObject)

    setCredential(response.credential)
  };

  useEffect(() => {
    /* global google */
    google.accounts.id.initialize({
      client_id: clientId,
      callback: handleCallbackResponse,
    });

    google.accounts.id.renderButton(document.getElementById('signInDiv'), {
      theme: 'outline',
      size: 'large',
      text: 'signin_with'
    });
  }, []);

  return (
    <Segment basic>
      <Header>Sign into JK Boots Fast Stock Lookup</Header>
      <Segment basic>
        <div id='signInDiv' />
      </Segment>
    </Segment>
  );
};
